import { Directus, ID, ActivityItem } from '@directus/sdk';
import { RepairTagger, Customer, Ticket, Invoice, RepairCat, RepairType } from '@/types'
import Dexie, { Table } from 'dexie';

//const connection = new Directus('https://test.frontline.local/live/');
export const repairTagger = new Directus<RepairTagger>(process.env.VUE_APP_DATA_SERVER as string)
export const customers = repairTagger.items('customers')
export const tickets = repairTagger.items('tickets')
export const ticket_repairs = repairTagger.items('ticket_repairs')
export const invoices = repairTagger.items('invoices')
export const repair_cats = repairTagger.items('repair_cats')
export const repair_types = repairTagger.items('repair_types')
export const invoice_settings = repairTagger.items('invoice_settings')
export const store_settings = repairTagger.items('store_settings')
export const zips = repairTagger.items('zips')


export async function recordUpdate(itemId:ID, lastUpdate:string):Promise<string|false> {
	try {
		const changes = await repairTagger.activity.readByQuery({
			limit: 1,
			sort: ['-timestamp'],
			filter: {
				item: { _eq: itemId as string }
			}
		})

		if(changes.data) {
			const change = changes.data[0] as ActivityItem

			if(!change) {
				throw "No update for " + itemId
			}

			const lastLocalUpdate = new Date(lastUpdate + "-04:00")
			const lastRemoteUpdate = new Date(change.timestamp + "Z")

			switch(change.action) {
				case 'delete':
					return 'delete'

				case 'update':
					if(lastRemoteUpdate > lastLocalUpdate) {
						console.log("recordUpdate: refreshing record…")
						return 'update'
					}
					return false

				default:
					return false
			}
		}

		throw "recordUpdate: could not get remote data."
	}
	catch(error) {
		console.error(error)

		return false
	}
}


export const localTicketFormat = [
	"*",
	"customer_id.id",
	"customer_id.first_name",
	"customer_id.last_name",
	"invoice_id.id",
	"invoice_id.locked",
	"ticket_repairs.*",
	"folder",
	"images.*",
	"pickup_date",
	"user_created.id",
	"user_updated.id"
]

export const localCustomerFormat = [
	"*",
	"tickets.id",
	"invoices.id",
	"user_created.id",
	"user_updated.id"
]

export const localInvoiceFormat = [
	"*",
	"customer_id.id",
	"customer_id.first_name",
	"customer_id.last_name",
	"tickets.id",
	"locked",
	"close_date",
	"user_created.id",
	"user_updated.id",
	"order_num",
	"sq_paylink"
]

export class rtDexie extends Dexie {
	// Tables are added by dexie when declaring the stores()
	// We just tell the typing system this is the case

	// IMPORTANT: if any changes are made to the schema,
	// increase the version number! Never lower it!

	customers!: Table<Customer>
	invoices!: Table<Invoice>
	tickets!: Table<Ticket>
	repair_cats!: Table<RepairCat>
	repair_types!: Table<RepairType>

	constructor() {
		super('rtDB');
		this.version(4).stores(
			{
				customers: `
					id,
					status,
					date_created,
					date_updated,
					user_created.id,
					user_updated.id,
					[first_name+last_name],
					[last_name+first_name],
					&email,
					&phone_main,
					street,
					street_2,
					city,
					state,
					zip,
					tickets,
					invoices
				`,
				tickets: `
					id,
					status,
					date_created,
					date_updated,
					user_created.id,
					user_updated.id,
					&tag_number,
					ticket_repairs.id,
					customer_id.id,
					customer_id.first_name,
					customer_id.last_name,
					invoice_id.id,
					invoice_id.locked,
					folder,
					pickup_date,
					notified_date
				`,
				invoices: `
					id,
					status,
					date_created,
					date_updated,
					user_created.id,
					user_updated.id,
					customer_id.id,
					customer_id.first_name,
					customer_id.last_name,
					summary,
					&number,
					notified,
					notified_date,
					amount_paid,
					total,
					is_paid,
					locked,
					close_date,
					tickets,
					tickets.id,
					order_num,
					sq_paylink
				`,
				repair_cats: `
					id,
					status,
					sort,
					date_created,
					date_updated,
					user_created.id,
					user_updated.id,
					&name,
					repair_types.id
				`,
				repair_types: `
					id,
					status,
					sort,
					date_created,
					date_updated,
					user_created.id,
					user_updated.id,
					&name,
					price,
					category_id.id
				`
			}
		).upgrade(() => {
			Dexie.delete('rtDB').then(() => {
				console.log("Reloading")
				location.reload()
			})

		})
	}
}

export const db = new rtDexie();