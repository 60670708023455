
import { defineComponent, toRaw, PropType } from 'vue'
import { formatDateTime, formatPhone } from '@/assets/ts/globals'
import { Ticket, Customer, Invoice, TicketPhoto } from '@/types'
import { repairTagger, customers, tickets, invoices, db, localCustomerFormat, localTicketFormat, localInvoiceFormat } from '@/directusSetup'
import { ID, FieldFilter } from '@directus/sdk';
import _ from 'lodash'


// This entire section could probably be optimized by DRYing it out.

// FIX: if a user switches the search from tag (#) to phone (+),
// it may not activate a search due to the character limit… but still look like a search has taken place.

export default defineComponent({
	name: "SearchView",
	props: {
		title: {
			type: String,
			required: true
		},
		scope: {
			type: String,
			required: false,
			default: null
		},
		multi: {
			type: Boolean,
			required: false,
			default: false
		},
		customer_id: {
			type: String as PropType<ID>,
			required: false,
			default: null
		},
		ticket_id: {
			type: String as PropType<ID>,
			required: false,
			default: null
		},
		preSelectedItems: {
			type: Array as PropType<ID[]>,
			required: false,
			default: null
		},
		limit: {
			type: Number,
			required: false,
			default: 20
		},
		archiveMode: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			showSearch: true,
			search: "",
			scopes: "customers,tickets,invoices,photos",
			searchLimit: this.limit,
			remoteDelay: 1000,
			customerRemoteTotal: 0,
			ticketRemoteTotal: 0,
			invoiceRemoteTotal: 0,
			errorMsg: "",
			searchingCustomers: false,
			searchingTickets: false,
			searchingInvoices: false,
			searchingPhotos: false,
			customerResults: null as null | Customer[],
			customerTimeout: null as null | ReturnType<typeof setTimeout>,
			ticketResults: null as null | Ticket[],
			ticketTimeout: null as null | ReturnType<typeof setTimeout>,
			invoiceResults: null as null | Invoice[],
			invoiceTimeout: null as null | ReturnType<typeof setTimeout>,
			photosResults: null as null | TicketPhoto[],
			photosTimeout: null as null | ReturnType<typeof setTimeout>,
			selectedIds: this.preSelectedItems ? this.preSelectedItems : [] as ID[],
			customerPrep: {
				first_name: "",
				last_name: "",
				phone: "",
				email:"",
				tag_number: ""
			}
		}
	},
	methods: {
		formatPhone,
		formatDateTime,
		cancel() {
			document.body.classList.remove('no-scroll')  // Restore scrolling
			this.$emit('cancelSearch')
		},
		loseFocus() {
			(this.$refs.search as HTMLInputElement).blur()
		},
		selectMultipleTickets() {
			try {
				let selected = []
				for(const ticketId of this.selectedIds) {
					const item = _.find(this.ticketResults, ['id', ticketId])
					selected.push(item)
				}

				if(!selected.length) {
					throw "no tickets selected!"
				}

				this.$emit("selectedItems", selected)
				this.$emit("selectedIds", this.selectedIds)
				this.cancel()
			}
			catch(error) {
				const err = error as Error
				console.error(err.message)
			}
		},
		selectCustomer(id: ID) {
			try {
				const selectedCustomer = _.find(this.customerResults, ['id', id])
				if(this.customerResults) {
					const customerData = toRaw(selectedCustomer) as Customer  // Removes reactive/proxy state
					db.customers.put(customerData)
				}
			}
			catch(error) {
				console.error(error)
			}

			this.$emit("selectedCustomer", id)
			this.cancel()
		},
		selectTicket(id: ID) {
			if(!this.multi) {
				try {
					const selectedTicket = _.find(this.ticketResults, ['id', id])
					if(this.customerResults) {
						const ticketData = toRaw(selectedTicket) as Ticket  // Remove reactive/proxy state
						db.tickets.put(ticketData)
					}
				}
				catch(error) {
					console.error(error)
				}

				this.$emit("selectedTicket", id)
				this.cancel()
			}
			else {
				const itemExists = _.indexOf(this.selectedIds, id)
				if(itemExists !== -1) {
					_.pull(this.selectedIds, id)
				}
				else {
					this.selectedIds.push(id)
				}
			}
		},
		selectInvoice(id: ID) {
			if(!this.multi) {
				try {
					const selectedInvoice = _.find(this.invoiceResults, ['id', id])
					if(this.customerResults) {
						const invoiceData = toRaw(selectedInvoice) as Invoice  // Remove reactive/proxy state
						db.invoices.put(invoiceData)
					}
				}
				catch(error) {
					console.error(error)
				}

				this.$emit("selectedInvoice", id)
				this.cancel()
			}
			else {
				const itemExists = _.indexOf(this.selectedIds, id)
				if(itemExists !== -1) {
					_.pull(this.selectedIds, id)
				}
				else {
					this.selectedIds.push(id)
				}
			}
		},
		resetTimeout(timer:string|null = null) {
			const timerResets = {
				customer: () => {
					if(this.customerTimeout) {
						clearTimeout(this.customerTimeout);
						this.customerTimeout = null
					}
				},
				ticket: () => {
					if(this.ticketTimeout) {
						clearTimeout(this.ticketTimeout);
						this.ticketTimeout = null
					}
				},
				invoice: () => {
					if(this.invoiceTimeout) {
						clearTimeout(this.invoiceTimeout);
						this.ticketTimeout = null
					}
				},
			}

			switch(timer) {
				case "customer":
					timerResets.customer()
					break;

				case "ticket":
					timerResets.ticket()
					break;

				case "invoice":
					timerResets.invoice()
					break;

				default:
					timerResets.customer()
					timerResets.ticket()
					timerResets.invoice()
			}
		},
		async findInvoiceByNumber(num: string) {
			this.resetTimeout("invoice")
			this.searchingInvoices = true

			let localResults: Invoice[]
			let remoteResults: Invoice[]

			try {
				localResults = await db.invoices
					.where("number")
					.startsWithIgnoreCase(num).toArray()

				this.invoiceTimeout = setTimeout(async () => {
					const directusResults = await invoices.readByQuery({
						fields: localInvoiceFormat,
						sort: ["number"],
						limit: this.searchLimit,
						meta: "filter_count",
						filter: { number: { _starts_with: num }}
					})

					remoteResults = directusResults.data as Invoice[]
					this.invoiceRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

					const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
					this.invoiceResults = _.values(mergedResults);

					this.searchingInvoices = false
				}, this.remoteDelay)

				this.invoiceResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findTicketByCustomerName(nameOne:string, nameTwo = "") {
			this.resetTimeout("ticket")
			this.searchingTickets = true

			let localResults: Ticket[]
			let remoteResults: Ticket[]

			try {
				if(!nameTwo) {
					localResults = await db.tickets
						.where("customer_id.first_name")
						.startsWithIgnoreCase(nameOne)
						.or("customer_id.last_name")
						.startsWithIgnoreCase(nameOne).toArray()

					this.ticketTimeout = setTimeout(async () => {
						const directusResults = await tickets.readByQuery({
							fields: localTicketFormat,
							sort: ["-date_updated"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_or: [
									{
										customer_id: {
											last_name: { _starts_with: nameOne }
										}
									},
									{
										customer_id: {
											first_name: { _starts_with: nameOne }
										}
									}
								]
							}
						})

						remoteResults = directusResults.data as Ticket[]
						this.ticketRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.ticketResults = _.values(mergedResults);

						this.searchingTickets = false
					}, this.remoteDelay)
				}
				else {
					console.log(`Searching for ${nameTwo} ${nameOne}…`)
					localResults = await db.tickets
						.where("customer_id.last_name")
						.startsWithIgnoreCase(nameOne)
						.and(item => item.customer_id.first_name.toLowerCase().includes(nameTwo.toLowerCase())).toArray()

					this.ticketTimeout = setTimeout(async () => {
						const directusResults = await tickets.readByQuery({
							fields: localTicketFormat,
							sort: ["-date_updated"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_and: [
									{
										customer_id: {
											last_name: { _starts_with: nameOne }
										}
									},
									{
										customer_id: {
											first_name: { _starts_with: nameTwo }
										}
									}
								]
							}
						})

						remoteResults = directusResults.data as Ticket[]
						this.ticketRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.ticketResults = _.values(mergedResults);

						this.searchingTickets = false
					}, this.remoteDelay)
				}

				this.ticketResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findInvoiceByCustomerName(nameOne:string, nameTwo = "") {
			this.resetTimeout("invoice")
			this.searchingInvoices = true

			let localResults: Invoice[]
			let remoteResults: Invoice[]

			try {
				if(!nameTwo) {
					localResults = await db.invoices
						.where("customer_id.first_name")
						.startsWithIgnoreCase(nameOne)
						.or("customer_id.last_name")
						.startsWithIgnoreCase(nameOne).toArray()

					this.invoiceTimeout = setTimeout(async () => {
						const directusResults = await invoices.readByQuery({
							fields: localInvoiceFormat,
							sort: ["-date_updated"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_or: [
									{
										customer_id: {
											last_name: { _starts_with: nameOne }
										}
									},
									{
										customer_id: {
											first_name: { _starts_with: nameOne }
										}
									}
								]
							}
						})

						remoteResults = directusResults.data as Invoice[]
						this.invoiceRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.invoiceResults = _.values(mergedResults);

						this.searchingInvoices = false
					}, this.remoteDelay)
				}
				else {
					console.log(`Searching for ${nameTwo} ${nameOne}…`)
					localResults = await db.invoices
						.where("customer_id.last_name")
						.startsWithIgnoreCase(nameOne)
						.and(item => item.customer_id.first_name.toLowerCase().includes(nameTwo.toLowerCase())).toArray()

					this.invoiceTimeout = setTimeout(async () => {
						const directusResults = await invoices.readByQuery({
							fields: localInvoiceFormat,
							sort: ["-date_updated"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_and: [
									{
										customer_id: {
											last_name: { _starts_with: nameOne }
										}
									},
									{
										customer_id: {
											first_name: { _starts_with: nameTwo }
										}
									}
								]
							}
						})

						remoteResults = directusResults.data as Invoice[]
						this.invoiceRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.invoiceResults = _.values(mergedResults);

						this.searchingInvoices = false
					}, this.remoteDelay)
				}

				this.invoiceResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findTicketsByCustomerId(id: ID) {
			console.log(this.$options.name + ": searching tickets by Customer ID, " + id)
			this.resetTimeout("ticket")
			this.searchingTickets = true

			let localResults: Ticket[]

			try {
				let remoteFilter = {} as FieldFilter<Ticket>

				if(this.archiveMode) {
					localResults = await db.tickets.where({ "customer_id.id": id }).toArray()

					remoteFilter = {
						customer_id: {
							id: { _eq: id }
						}
					}
				}
				else {
					localResults = await db.tickets.where({ "customer_id.id": id })
						.and(item => (item.invoice_id as Invoice)?.id == null)
						.toArray()

					remoteFilter = {
						status: { _neq: "archived" },
						customer_id: {
							id: { _eq: id }
						},
						invoice_id: { _null: true }
					}
				}

				this.ticketResults = localResults

				const directusResults = await tickets.readByQuery({
					fields: localTicketFormat,
					sort: ["tag_number"],
					limit: this.searchLimit,
					meta: "filter_count",
					filter: remoteFilter
				})

				const remoteResults = directusResults.data as Ticket[]
				this.ticketRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

				const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
				this.ticketResults = _.values(mergedResults);

				this.searchingTickets = false
			}
			catch(error) {
				console.error(error)
			}
		},
		async findInvoicesByCustomerId(id: ID) {
			// This should only be used for the Customer Invoice Archive right now.

			console.log(this.$options.name + ": searching invoices by Customer ID, " + id)
			this.resetTimeout("invoice")
			this.searchingInvoices = true

			let localResults: Invoice[]

			try {
				let remoteFilter = {} as FieldFilter<Invoice>

				localResults = await db.invoices.where({ "customer_id.id": id }).toArray()

				remoteFilter = {
					customer_id: {
						id: { _eq: id }
					}
				}

				this.invoiceResults = localResults

				const directusResults = await invoices.readByQuery({
					fields: localInvoiceFormat,
					limit: this.searchLimit,
					meta: "filter_count",
					filter: remoteFilter
				})

				const remoteResults = directusResults.data as Invoice[]
				this.invoiceRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

				const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
				this.invoiceResults = _.values(mergedResults);

				this.searchingInvoices = false
			}
			catch(error) {
				console.error(error)
			}
		},
		async findTicketsByTag(tag:string) {
			this.resetTimeout("ticket")
			this.searchingTickets = true

			let localResults: Ticket[]
			let remoteResults: Ticket[]

			try {
				localResults = await db.tickets
					.where("tag_number")
					.startsWithIgnoreCase(tag).toArray()

				this.ticketTimeout = setTimeout(async () => {
					const directusResults = await tickets.readByQuery({
						fields: localTicketFormat,
						sort: ["tag_number"],
						limit: this.searchLimit,
						meta: "filter_count",
						filter: { tag_number: { _starts_with: tag }}
					})

					remoteResults = directusResults.data as Ticket[]
					this.ticketRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

					const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
					this.ticketResults = _.values(mergedResults);

					this.searchingTickets = false
				}, this.remoteDelay)

				this.ticketResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findCustomersByName(nameOne:string, nameTwo = "") {
			this.resetTimeout("customer")
			this.searchingCustomers = true

			let localResults: Customer[]
			let remoteResults: Customer[]

			try {
				if(!nameTwo) {
					localResults = await db.customers
						.where("first_name")
						.startsWithIgnoreCase(nameOne)
						.or("last_name")
						.startsWithIgnoreCase(nameOne).toArray()

					this.customerTimeout = setTimeout(async () => {
						const directusResults = await customers.readByQuery({
							fields: localCustomerFormat,
							sort: ["last_name"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_or: [
									{ last_name: { _starts_with: nameOne }},
									{ first_name: { _starts_with: nameOne }}
								]
							}
						})

						remoteResults = directusResults.data as Customer[]
						this.customerRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.customerResults = _.values(mergedResults);

						this.searchingCustomers = false
					}, this.remoteDelay)
				}
				else {
					console.log(`Searching for ${nameTwo} ${nameOne}…`)
					localResults = await db.customers
						.where("last_name")
						.startsWithIgnoreCase(nameOne)
						.and(item => item.first_name.toLowerCase().includes(nameTwo.toLowerCase())).toArray()

					this.customerTimeout = setTimeout(async () => {
						const directusResults = await customers.readByQuery({
							fields: localCustomerFormat,
							sort: ["last_name"],
							limit: this.searchLimit,
							meta: "filter_count",
							filter: {
								_and: [
									{ last_name: { _starts_with: nameOne }},
									{ first_name: { _starts_with: nameTwo }}
								]
							}
						})

						remoteResults = directusResults.data as Customer[]
						this.customerRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

						const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
						this.customerResults = _.values(mergedResults);

						this.searchingCustomers = false
					}, this.remoteDelay)
				}

				this.customerResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findCustomerByPhone(num:string) {  // Counterintuitively, we need to keep the number as a string
			this.resetTimeout("customer")
			this.searchingCustomers = true

			let localResults: Customer[]
			let remoteResults: Customer[]

			try {
				localResults = await db.customers
					.where("phone_main")
					.startsWith(num).toArray()

				this.customerTimeout = setTimeout(async () => {
					const directusResults = await customers.readByQuery({
						fields: localCustomerFormat,
						sort: ["phone_main"],
						limit: this.searchLimit,
						meta: "filter_count",
						filter: { phone_main: { _contains: num }}
					})

					remoteResults = directusResults.data as Customer[]
					this.customerRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

					const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
					this.customerResults = _.values(mergedResults);

					this.searchingCustomers = false
				}, this.remoteDelay)

				this.customerResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findCustomerByEmail(email:string) {
			this.resetTimeout("customer")
			this.searchingCustomers = true

			let localResults: Customer[]
			let remoteResults: Customer[]

			try {
				localResults = await db.customers
					.where("email")
					.startsWith(email).toArray()

				this.customerTimeout = setTimeout(async () => {
					const directusResults = await customers.readByQuery({
						fields: localCustomerFormat,
						sort: ["email"],
						limit: this.searchLimit,
						meta: "filter_count",
						filter: { email: { _starts_with: email }}
					})

					remoteResults = directusResults.data as Customer[]
					this.customerRemoteTotal = directusResults.meta?.filter_count ? directusResults.meta?.filter_count : 0

					const mergedResults = _.merge(_.keyBy(localResults, 'id'), _.keyBy(remoteResults, 'id'));
					this.customerResults = _.values(mergedResults);

					this.searchingCustomers = false
				}, this.remoteDelay)

				this.customerResults = localResults
			}
			catch(error) {
				console.error(error)
			}
		},
		async findPhotoByTitle(title: string) {
			this.resetTimeout("photos")
			this.searchingPhotos = true

			try {
				this.photosTimeout = setTimeout(async () => {
					const results = await repairTagger.files.readByQuery({
						search: title,
						fields: ['id', 'title', 'ticket_id.*']
					})

					if(results?.data) {
						this.photosResults = results.data as TicketPhoto[]
					}

				})
			}
			catch(error) {
				console.error(error)
			}
		},
		createUrl(id: ID, key="240sq") {
			if(process.env.VUE_APP_DATA_SERVER)
				return process.env.VUE_APP_DATA_SERVER + `assets/${id}?key=${key}&access_token=` + localStorage.getItem('auth_token')
		},
		prepCustomer() {
			this.$router.push({
				path: "/customers/new",
				query: {
					first_name: this.customerPrep.first_name,
					last_name: this.customerPrep.last_name,
					phone: this.customerPrep.phone,
					email: this.customerPrep.email,
					tag_number: this.customerPrep.tag_number
				}
			})
		}
	},
	watch: {
		search(search) {
			if(search.length >= 4) {
				switch(true) {
					case search.startsWith("$"): {
						if(this.scopes.includes("invoices")) {
							const num = search.replace("$", "")
							if(num) {
								this.findInvoiceByNumber(num)
							}
						}
						break;
					}

					case search.startsWith("+"): {
						// Is phone number
						if(this.scopes.includes("customers")) {
							const phone = search.replace("+", "")

							if(phone.length >= 5) {
								console.log("Phone number")
								this.findCustomerByPhone(phone)
								this.customerPrep.phone = phone
							}
							else {
								this.customerResults = null
								this.customerPrep.phone = ""
							}
						}
						break;
					}

					case isNaN(search) && /\d/.test(search):
					case search.startsWith('#'): {
						// Tag
						if(this.scopes.includes("tickets")) {
							const tag = search.replace("#", "")
							if(tag) {
								console.log(tag)
								this.findTicketsByTag(tag)
								this.customerPrep.tag_number = tag
							}
							else {
								this.customerPrep.tag_number = ""
							}
						}

						break;
					}

					case search.includes("@"): {
						// is email
						if(this.scopes.includes("customers")) {
							console.log("email")
							this.findCustomerByEmail(search)
							this.customerPrep.email = search
						}
						break;
					}

					case search.includes(" "):
					case search.includes(","): {
						// is name
						console.log("Customer Name")
						let last_name, first_name

						if(search.includes(",")) {
							const names = search.split(",")
							last_name = names[0].trim()
							first_name = names[1].trim()

							if(last_name == "") {
								last_name = first_name
								first_name = ""
							}

							if(this.scopes.includes("customers")) {
								this.findCustomersByName(last_name, first_name)
							}

							if(this.scopes.includes("tickets")) {
								this.findTicketByCustomerName(last_name, first_name)
							}

							if(this.scopes.includes("invoices")) {
								this.findInvoiceByCustomerName(last_name, first_name)
							}
						}
						else {
							const names = search.split(" ")
							if(names.length > 2) {
								this.errorMsg = "When searching for a name like “Mary Jane Watson,” be sure to use the <b>Last&nbsp;Name,&nbsp;First&nbsp;Name</b> format: Watson, Mary Jane"
							}
							else {
								first_name = names[0]
								last_name = names[1]

								if(last_name == "") {
									last_name = first_name
									first_name = ""
								}

								if(this.scopes.includes("customers")) {
									this.findCustomersByName(last_name, first_name)
								}

								if(this.scopes.includes("tickets")) {
									this.findTicketByCustomerName(last_name, first_name)
								}

								if(this.scopes.includes("invoices")) {
									this.findInvoiceByCustomerName(last_name, first_name)
								}
							}
						}
						this.customerPrep.first_name = first_name
						this.customerPrep.last_name = last_name

						break;
					}
					case !isNaN(search): {
						// Could be tag or phone or invoice
						console.log("Tag or phone")

						const cleanSearch = search.replace("#", "").replace("+", "").replace("$", "")
						if(this.scopes.includes("customers")) {
							this.findCustomerByPhone(cleanSearch)
						}

						if(this.scopes.includes("tickets")) {
							this.findTicketsByTag(cleanSearch)
						}

						if(this.scopes.includes("invoices")) {
							this.findInvoiceByNumber(cleanSearch)
						}
						break;
					}

					default: {
						// Could be a tag or a customer name
						console.log("Tag or name")

						if(this.scopes.includes("customers"))
							this.findCustomersByName(search)

						if(this.scopes.includes("tickets"))
							this.findTicketsByTag(search)

						if(this.scopes.includes("tickets"))
							this.findTicketByCustomerName(search)

						if(this.scopes.includes("invoices"))
							this.findInvoiceByCustomerName(search)

						if(this.scopes.includes("photos"))
							this.findPhotoByTitle(search)

					}
				}
			}
			else {
				this.resetTimeout()
				this.customerResults = null
				this.ticketResults = null
				this.invoiceResults = null
			}
		}
	},
	created() {
		document.body.classList.add('no-scroll')  // Prevent background scroll

		if(this.scope) {
			this.scopes = this.scope
		}

		if(this.customer_id) {
			this.showSearch = false

			if(this.scopes.includes("tickets")) {
				this.findTicketsByCustomerId(this.customer_id)
			}

			if(this.scopes.includes("invoices")) {
				this.findInvoicesByCustomerId(this.customer_id)
			}
		}
	},
	mounted() {
		if(this.$refs.search) {
			(this.$refs.search as HTMLInputElement).focus()
		}

		if(this.$route.params.tag_number) {
			this.customerPrep.tag_number = this.$route.params.tag_number as string
		}
	},
	unmounted() {
		document.body.classList.remove('no-scroll')  // Restore scrolling
	}
})
