
import { defineComponent, Ref, computed } from 'vue';
import { Filter } from '@directus/sdk'
import { User, Customer, Ticket, Invoice, RepairCat, RepairType } from '@/types'
import { RoleItem } from '@directus/sdk';
import { repairTagger, customers, tickets, invoices, repair_cats, repair_types, db, localCustomerFormat, localTicketFormat, localInvoiceFormat } from '@/directusSetup'
import { liveQuery } from "dexie";
import { useObservable } from '@vueuse/rxjs'
import LoginView from '@/views/LoginView.vue'

// Configure Defaults
if(!localStorage.getItem("customerSort"))
	localStorage.setItem("customerSort", "last_name")

if(!localStorage.getItem("ticketSort"))
	localStorage.setItem("ticketSort", "status")

if(!localStorage.getItem("invoiceSort"))
	localStorage.setItem("invoiceSort", "status")


export default defineComponent({
	name: "RepairTagger",
	components: {
		LoginView,
	},
	data() {
		return {
			authorized: false,
			forceLogout: false,
			user: null as null | User,
			role: null as null | RoleItem,
			sectionName: "RepairTagger",
			recordLimit: 200,
			customerSort: "first_name" as keyof Customer,
			ticketSort: "status" as keyof Ticket,
			invoiceSort: "status" as keyof Invoice,
			customers: useObservable(
				// eslint-disable-next-line
				liveQuery(() => db.customers.toArray()) as any
			) as Ref<Customer[]>,
			tickets: useObservable(
				// eslint-disable-next-line
				liveQuery(() => db.tickets.toArray()) as any
			) as Ref<Ticket[]>,
			invoices: useObservable(
				// eslint-disable-next-line
				liveQuery(() => db.invoices.toArray()) as any
			) as Ref<Invoice[]>
		}
	},
	provide() {
		return {
			repair_cats: computed(() => useObservable(
					// eslint-disable-next-line
					liveQuery(() => db.repair_cats.orderBy("name").toArray()) as any
				) as Ref<RepairCat[]>
			),
			repair_types: computed(() => useObservable(
					// eslint-disable-next-line
					liveQuery(() => db.repair_types.orderBy("name").toArray()) as any
				) as Ref<RepairType[]>
			),
			user: computed(() => this.user),
			role: computed(() => this.role?.id)
		}
	},
	methods: {
		setAuth(auth: boolean) {
			this.authorized = auth
		},
		async logout() {
			this.forceLogout = true
			this.authorized = false
			await repairTagger.auth.logout()
		},
		setSection(section: string) {
			// This doesn't do anything right now; we probably don't need it.
			this.sectionName = section
		},
		async getCustomers() {
			console.info("Getting customers…")
			try {

				// NEED TO UPDATE FILTERS BASED ON ARCHIVED INVOICES

				let filter = {
					tickets: {
						_or: [
							{ invoice_id: { _null: true } },
							{ invoice_id: { status: { _neq: 'archived'} } }
						]
					}
				} as Filter<Customer>

				const lastUpdate = localStorage.getItem("lastCustomerUpdate")
				if(lastUpdate) {
					filter = {
						tickets: {
							_or: [
								{ invoice_id: { _null: true } },
								{ invoice_id: { status: { _neq: 'archived'} } }
							]
						},
						date_updated: { _gte: lastUpdate + "Z" }
					} as Filter<Customer>
				}

				const activeCustomers = await customers.readByQuery({
					limit: this.recordLimit,
					filter: filter,
					fields: localCustomerFormat
				})

				const now = new Date().toISOString().replace("Z", "")
				localStorage.setItem("lastCustomerUpdate", now)

				if(activeCustomers.data) {
					console.log("Customers downloaded: " + activeCustomers.data.length)
					if(activeCustomers.data.length)
						db.customers.bulkPut(activeCustomers.data as Customer[])
				}
				else {
					throw "Could not get Active Customers."
				}
			}
			catch(error) {
				console.error(error)
			}
		},
		async getTickets() {
			console.info("Getting tickets…")
			try {
				let filter = {
					_or: [
						{ invoice_id: { _null: true } },
						{ invoice_id: { status: { _neq: 'archived'} } }
					]
				} as Filter<Ticket>

				const lastUpdate = localStorage.getItem("lastTicketUpdate")
				if(lastUpdate) {
					filter = {
						_or: [
							{ invoice_id: { _null: true } },
							{ invoice_id: { status: { _neq: 'archived'} } }
						],
						date_updated: { _gte: lastUpdate + "Z" }
					}
				}

				const activeTickets = await tickets.readByQuery({
					limit: this.recordLimit,
					filter: filter,
					fields: localTicketFormat
				})

				const now = new Date().toISOString().replace("Z", "")
				localStorage.setItem("lastTicketUpdate", now)

				if(activeTickets.data) {
					console.log("Tickets downloaded: " + activeTickets.data.length)
					if(activeTickets.data.length)
						db.tickets.bulkPut(activeTickets.data as Ticket[])
				}
				else {
					throw "Could not get Active Tickets."
				}
			}
			catch(error) {
				console.error(error)
			}
		},
		async getInvoices() {
			console.info("Getting invoices…")
			try {
				let filter = {
					status: { _neq: "archived" }
				} as Filter<Invoice>

				const lastUpdate = localStorage.getItem("lastInvoiceUpdate")
				if(lastUpdate) {
					filter = {
						status: { _neq: "archived" },
						date_updated: { _gte: lastUpdate + "Z" }
					}
				}

				const activeInvoices = await invoices.readByQuery({
					limit: this.recordLimit,
					filter: filter,
					fields: localInvoiceFormat
				})

				const now = new Date().toISOString().replace("Z", "")
				localStorage.setItem("lastInvoiceUpdate", now)

				if(activeInvoices.data) {
					console.log("Invoices downloaded: " + activeInvoices.data.length)
					if(activeInvoices.data.length)
						db.invoices.bulkPut(activeInvoices.data as Invoice[])
				}
				else {
					throw "Could not get Active Invoices."
				}
			}
			catch(error) {
				console.error(error)
			}
		},
		async getRepairCats() {
			try {
				const sort = ['sort', 'name'] as never[]

				const cats = await repair_cats.readByQuery({
					limit: -1,
					sort: sort,
					fields: ['*', 'repair_types.id', 'user_created.id', 'user_updated.id']
				})
				db.repair_cats.bulkPut(cats.data as RepairCat[])
			}
			catch(error) {
				console.error(error)
			}
		},
		async getRepairTypes() {
			try {
				const sort = ['sort', 'name'] as never[]

				const types = await repair_types.readByQuery({
					limit: -1,
					sort: sort,
					fields: ['*', 'category_id.id', 'user_created.id', 'user_updated.id']
				})
				db.repair_types.bulkPut(types.data as RepairType[])
			}
			catch(error) {
				console.error(error)
			}
		},
		async getUserData() {
			if(!this.user) {
				try {
					const lookup = await repairTagger.users.me.read({
						fields: ['*', 'role.*']
					}) as User

					if(!lookup) {
						throw "cannot lokup User."
					}

					this.user = lookup
					this.role = lookup.role.id

					console.log(lookup)
				}
				catch(error) {
					console.error(error)
				}
			}
		}
	},
	watch: {
		authorized() {
			if(this.authorized) {
				this.getUserData()
				this.getRepairCats()
				this.getRepairTypes()
				this.getUserData()
			}
		},
		invoices() {
			console.warn("Invoices Updated")
		},
		tickets() {
			console.warn("Tickets Updated")
		},
		customers() {
			console.warn("Customers Updated")
		}
	}
})

