
import { defineComponent } from 'vue'
import { repairTagger, db } from '@/directusSetup'

export default defineComponent({
	name: "LoginView",
	props: ['forceLogout'],
	data() {
		return {
			email: "",
			password: "",
			loginStatus: ""
		}
	},
	methods: {
		async directusAuthCheck() {
			console.info("Logging in with token…")
			// Try to authenticate with token, if exists
			await repairTagger.auth
				.refresh()
				.then(() => {
					this.password = ""
					this.$emit('directusLogin', true)
				})
				.catch(() => {
					console.log("Not authorized.")
					this.$emit('directusLogin', false)
				});
		},
		async directusLogin() {
			console.info("Logging in…")

			// Reset local storage
			localStorage.setItem("lastCustomerUpdate", "")
			localStorage.setItem("lastTicketUpdate", "")
			localStorage.setItem("lastInvoiceUpdate", "")

			// Reset IndexedDB via Dexie
			db.customers.clear()
			db.tickets.clear()
			db.invoices.clear()
			db.repair_cats.clear()
			db.repair_types.clear()

			// Attempt to login
			let email = this.email
			let password = this.password

			repairTagger.auth
				.login({
					email,
					password
				})
				.then(() => {
					console.log("Login successful.")
					this.$emit('directusLogin', true)
				})
				.catch((err) => {
					console.log(err)
					this.password = ""
					this.loginStatus = err
				});
		}
	},
	created() {
		if(!this.forceLogout)
			this.directusAuthCheck()
	},
	mounted() {
		this.loginStatus = ""
	}
})
