import MarkdownIt from 'markdown-it'
import { parsePhoneNumber } from 'libphonenumber-js'


export function scrollDetails() {
	if(document.querySelector(".main-details")) {
		const details = document.querySelector(".main-details") as Element
		console.log("Scrolling Details…")
		details.scroll({top: 0})
	}
}

// Should probably move this to directusSetup.ts

export function formatPhone(num: string): string {
	try {
		const phone = parsePhoneNumber("+" + num).formatNational()
		return phone.replace("-", "‑")  // Replace a hyphen with a non-breaking hyphen.
	}
	catch(error) {
		console.log(error)
		return "Poorly formatted phone number."
	}
}

export function formatDateTime(date:string, format = "full"):string {
	try {
		if(date) {
			const dateTime = new Date(date + "-04:00")
			const recently = new Date(dateTime)
			recently.setHours(dateTime.getHours() - 24)
			let returnFormat = {}

			const dateTimeFormat = {
				month: "numeric",
				day: "numeric",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true
			}
			const dateFormat = {
				month: "numeric",
				day: "numeric",
				year: "numeric"
			}
			const timeFormat = {
				hour: "numeric",
				minute: "numeric",
				hour12: true
			}

			switch(format) {
				case "short":
					if(dateTime > recently) {
						returnFormat = timeFormat
					}
					else {
						returnFormat = dateTimeFormat
					}
					break

				case "full":
					returnFormat = dateTimeFormat
					break

				case "date-only":
					returnFormat = dateFormat
					break

				case "time-only":
					returnFormat = timeFormat
			}


			return dateTime.toLocaleString('en-US', returnFormat)
		}
		else {
			throw "Could not format date: no date provided."
		}
	}
	catch(error) {
		const err = error as Error
		console.error(err.message)
		return "Could not get date."
	}
}

export function formatListDate(dateCreated:string, dateUpdated:string|null|undefined = null) {
	if(dateUpdated) {
		return formatDateTime(dateUpdated, "short")
	}
	else if(dateCreated) {
		return formatDateTime(dateCreated, "short")
	}
	else {
		return "Date error."
	}
}

export function formatCurrency(amount: number):string {
	try {
		if(!isNaN(amount)) {
			const currency = new Intl.NumberFormat("en-US", {
				style: 'currency',
				currency: 'USD'
			}).format(amount)
			return currency
		}
		else {
			console.error("Cannot format currency: amount is not a number.")
			return "[Can't total]"
		}
	}
	catch(error) {
		console.error(error)
		return "[Error]"
	}
}

export function formatMarkdown(markdown:string):string {
	try {
		if(markdown) {
			const md = new MarkdownIt({ linkify: true, breaks: true }).disable(['heading'])
			return md.render(markdown)
		}
		else {
			return ""
		}
	}
	catch(error) {
		console.error(error)
		return "Error: could not format data."
	}
}
