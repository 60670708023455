import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/main/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/:alert?',
		name: 'Home',
		meta: {
			title: "Home"
		},
		components: {
			Home: HomeView
		}
	},
	{
		path: '/tagger',
		name: 'Tagger',
		meta: {
			title: "Tagger"
		},
		components: {
			Tagger: () => import(/* webpackChunkName: "tagger" */ '@/views/main/TaggerView.vue')
		}
	},
	{
		path: '/customers',
		name: 'Customers',
		meta: {
			title: "Customers"
		},
		components:{
			Customers: () => import(/* webpackChunkName: "customers" */ '@/views/main/CustomerView.vue')
		},
		children: [
			{
				path: ':id',
				name: 'CustomerDetails',
				meta: {
					title: "Customer"
				},
				component: () => import(/* webpackChunkName: "customerDetails" */ '@/views/details/CustomerDetails.vue')
			},
		]
	},
	{
		path: '/customers/new',
		name: 'NewCustomer',
		meta: {
			title: "New Customer"
		},
		props: true,
		component: () => import(/* webpackChunkName: "newCustomer" */ '@/views/new/NewCustomer.vue')
	},
	{
		path: '/invoices',
		name: 'Invoices',
		meta: {
			title: "Invoices"
		},
		components: {
			Invoices: () => import(/* webpackChunkName: "invoices" */ '@/views/main/InvoiceView.vue')
		},
		children: [
			{
				path: ':id',
				name: 'InvoiceDetails',
				meta: {
					title: "Invoice"
				},
				component: () => import(/* webpackChunkName: "invoiceDetails" */ '@/views/details/InvoiceDetails.vue')
			}
		]
	},
	{
		path: '/invoices/new',
		name: 'NewInvoice',
		meta: {
			title: "New Invoice"
		},
		props: true,
		component: () => import(/* webpackChunkName: "newInvoice" */ '@/views/new/NewInvoice.vue')
	},
	{
		path: '/tickets',
		name: 'Tickets',
		meta: {
			title: "Tickets"
		},
		components: {
			Tickets: () => import(/* webpackChunkName: "tickets" */ '@/views/main/TicketView.vue')
		},
		children: [
			{
				path: ':id',
				name: 'TicketDetails',
				meta: {
					title: "Ticket"
				},
				component: () => import(/* webpackChunkName: "ticketDetails" */ '@/views/details/TicketDetails.vue')
			}
		]
	},
	{
		path: '/tickets/new/:tag_number?',
		name: 'NewTicket',
		meta: {
			title: "New Ticket"
		},
		props: true,
		component: () => import(/* webpackChunkName: "newTicket" */ '@/views/new/NewTicket.vue')
	},
	{
		path: '/settings',
		name: 'Settings',
		meta: {
			title: "Settings"
		},
		component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/SettingsView.vue')
	},
	{
		path: '/settings/items',
		name: 'RepairCatConfig',
		meta: {
			title: "Categories & Items"
		},
		component: () => import(/* webpackChunkName: "repairCatConfig" */ '@/views/settings/RepairCatConfig.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title as string
	next()
})

export default router
